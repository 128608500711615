import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAllAccountPaymentMethods } from '../../../selectors/orderSelectors'

const TillPaymentDetails = ({ payment }) => {
  const { paymentMethod, creditCardType, nameOnCreditCard, creditCardLastFour, accountPaymentMethod } = payment
  const paymentMethods = useSelector(getAllAccountPaymentMethods)
  const { t } = useTranslation()

  return (
    <>
      <p className="mb-1">
        {accountPaymentMethod?.accountPaymentMethodID?.length > 0 &&
          paymentMethods.map(method => {
            return method.accountPaymentMethodID === accountPaymentMethod?.accountPaymentMethodID ? <em key={accountPaymentMethod?.accountPaymentMethodID}>{method?.accountPaymentMethodName}</em> : null
          })}
        <br />
        <em>{paymentMethod?.paymentMethodName}</em>
        <br />
        {nameOnCreditCard} <br />
        <span>
          <span className="text-uppercase">{creditCardType}</span> {t('frontend.account.payment_method.ending_in')} {creditCardLastFour}
        </span>
      </p>
    </>
  )
}

export { TillPaymentDetails }
