const TermPaymentDetails = ({ termPayment, hideHeading }) => {
  const { purchaseOrderNumber, paymentMethod } = termPayment

  return (
    <>
      <em>{paymentMethod.paymentMethodName}</em>
      <br />
      {purchaseOrderNumber}
    </>
  )
}
export { TermPaymentDetails }
