const CashPaymentDetails = ({ cashPayment, hideHeading }) => {
  const { paymentMethod } = cashPayment

  return (
    <>
      <p className="mb-1">
        <em>{paymentMethod.paymentMethodName}</em>
        <br />
      </p>
    </>
  )
}
export { CashPaymentDetails }
