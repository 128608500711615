import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { updateUser } from '../../../actions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'
import { SlatwalApiService } from '../../../services'
import { useEffect } from 'react'
import { useGetAllCustomAttributes } from '../..'
import { useState } from 'react'

const useAccountProfile = () => {
  const dispatch = useDispatch()
  const MySwal = withReactContent(Swal)
  const user = useSelector(state => state.userReducer)
  const [isLoaded,setLoaded] = useState(false)
  let [customAttributes, setRequest] = useGetAllCustomAttributes()
  const [invalidTracker, setInvalidTracker] = useState({})
  const [payload, setResponse] = useState({})
  const setValue = attributeCode => func => {
    // returns a curried function that acts in the same way as the set response hook
    // but will delete the specified attribute code from the invalid tracker
    setResponse(func)
    setInvalidTracker(tracker => {
      const copy = { ...tracker }
      delete copy[attributeCode]
      return copy
    })
  }

  const [currentSchema, setCurrentSchema] = useState({
    accountFirstName: user.firstName,
    accountLastName: user.lastName,
    accountEmailAddress: user.primaryEmailAddress.emailAddress,
    // accountPhoneNumber: user.primaryPhoneNumber.phoneNumber,
    // accountExt: '',
    accountCompany: user.company,
  })

  useEffect(() => {
    let didCancel = false
    if (!customAttributes.isFetching && !customAttributes.isLoaded && !didCancel) {
      setRequest({
        ...customAttributes,
        isFetching: true,
        isLoaded: false,
        params: {
          'f:attributeSet.attributeSetObject': 'Account',
          'f:attributeSet.globalFlag': 'true',
          'f:allowUpdateFlag': 'true',
        },
        makeRequest: true,
      })
    }
    return () => {
      didCancel = true
    }
  }, [customAttributes, setRequest])

  useEffect(() => {
    if (customAttributes?.data) {
      let customObjectSchema = {}
      customAttributes?.data.map(({attributeCode}) => customObjectSchema[attributeCode] = user[attributeCode])
      setCurrentSchema({ ...currentSchema,...customObjectSchema})
      setResponse({...customObjectSchema,...payload})
      setLoaded(true)
    }
    //eslint-disable-next-line
  }, [customAttributes])

  const submitForm = (values) => {
    let initialPayload = {
      firstName: values.accountFirstName,
      lastName: values.accountLastName,
      primaryEmailAddress: {
        emailAddress: values.accountEmailAddress,
        accountEmailAddressID: user.primaryEmailAddress.accountEmailAddressID,
      },
      company: values.accountCompany,
      returnJSONObjects: 'account',
    }
    dispatch(
      updateUser({...initialPayload,...payload})
    )
  }
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currentSchema,
    onSubmit: values => {
      submitForm(values)
    },
  })

  const updatePassword = event => {
    event.preventDefault()

    MySwal.fire({
      title: 'Update Password',
      html: '<input id="accountPassword" placeholder="Password" class="swal2-input"><input id="accountPasswordConfirm" placeholder="Confirm Password" class="swal2-input">',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [document.getElementById('accountPassword').value, document.getElementById('accountPasswordConfirm').value]
      },
    }).then(data => {
      if (data.isConfirmed) {
        if (data.value.length === 2 && data.value?.at(0) === data.value[1]) {
          SlatwalApiService.account
            .changePassword({
              password: data.value?.at(0),
              passwordConfirm: data.value[1],
            })
            .then(response => {
              if (response.isSuccess()) {
                if (response.success().successfulActions.length) {
                  toast.success('Password Update Successful')
                } else {
                  toast.error(response.success().errors.password.join(' '))
                }
              } else {
                toast.error('Network Error')
              }
            })
        } else {
          toast.error('Password Mismatch')
        }
      }
    })
  }

  let formikNew = customAttributes?.data ? formik : {}
  return { formik:formikNew, customAttributes, updatePassword, isLoaded, setValue, invalidTracker, payload }
}

export { useAccountProfile }
