import { useCheckoutUtilities, useFormatCurrency } from '../../../hooks'
import { useSelector } from 'react-redux'
import { GiftCardDetails, CCDetails, CheckPaymentDetails, TermPaymentDetails, ExternalPaymentDetails, CashPaymentDetails } from '../..'
import { useTranslation } from 'react-i18next'
import { CHECK_PAYMENT_CODE } from '../../../hooks/components/Checkout/useCheckoutUtilities'

const PaymentList = ({ resetSelection, payments, onRemovePayment = () => {}, disableInteraction = false , setChangePaymentDue}) => {
  const { CREDIT_CARD_CODE, GIFT_CARD_CODE, TERM_PAYMENT_CODE, CASH_PAYMENT_CODE, EXTERNAL_PAYMENT_CODE } = useCheckoutUtilities()
  const { t } = useTranslation()
  const [formatCurrency] = useFormatCurrency({})
  const cart = useSelector(state => state.cart)
  if (payments.length === 0) return null
  return (
    <>
      <p className="h4">{t('frontend.checkout.payments')}:</p>
      <div className="col-md-8 d-flex">
        <div className="bg-lightgray col p-3">
          <h6 className="h6">{t('frontend.checkout.payment_method')}</h6>
          {payments.map(payment => {
            return (
              <>
                {payment.paymentMethod.paymentMethodType === CREDIT_CARD_CODE && <CCDetails hideHeading={true} creditCardPayment={payment} />}
                {payment.paymentMethod.paymentMethodType === GIFT_CARD_CODE && <GiftCardDetails method={payment.paymentMethod.paymentMethodType} payment={payment} />}
                {payment.paymentMethod.paymentMethodType === TERM_PAYMENT_CODE && <TermPaymentDetails hideHeading={true} termPayment={payment} />}
                {payment.paymentMethod.paymentMethodType === CASH_PAYMENT_CODE && <CashPaymentDetails hideHeading={true} cashPayment={payment} />}
                {payment.paymentMethod.paymentMethodType === CHECK_PAYMENT_CODE && <CheckPaymentDetails hideHeading={true} payment={payment} />}
                {payment.paymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE && <ExternalPaymentDetails hideHeading={true} payment={payment} />}
                <hr />
                <button
                  className="btn btn-link px-0 text-danger"
                  type="button"
                  disabled={disableInteraction}
                  onClick={event => {
                    resetSelection()
                    onRemovePayment({ orderPaymentID: payment.orderPaymentID })
                  }}
                >
                  <i className="fal fa-times-circle"></i>
                  <span className="small"> {t('frontend.core.changeSelection')}</span>
                </button>
              </>
            )
          })}
          {cart.orderPaymentAmountNeeded > 0 && (
            <>
              <p> {t('frontend.checkout.multiplePayment.paymentAmountDue') + ' ' + formatCurrency(cart.orderPaymentAmountNeeded)}</p>
              <button
                className="btn btn-link px-0 text-danger"
                type="button"
                disabled={disableInteraction}
                onClick={event => {
                  setChangePaymentDue(true)
                }}
              >
                <i className="fal fa-times-circle"></i>
                <span className="small"> {t('frontend.checkout.multiplePayment.clickAmountDue')}</span>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}
export { PaymentList }
