const SwCheckboxSelect = ({ label, onChange, options = [], selectedValue, errorMsg }) => {
    return (
      <div className="form-group">
        {/* don't pass label for custom label */}
        {label && (
          <>
            <label className="w-100 h4">{label}</label>
          </>
        )}
  
        <div className="d-flex flex-column">
          {options.length > 0 &&
            options.map(({ value, name, code }) => {
              return (
                <div key={value} className="form-check form-check-inline custom-control custom-radio align-items-center d-inline-flex" style={{ zIndex: 0 }}>
                  <input className="custom-control-input" type="checkbox" id={code || value} value={value} onChange={e => onChange(e.target.value)} checked={selectedValue?.split(',').includes(value) ? true : false} />
                  <label
                    className="my-1 ms-2 custom-control-label"
                    htmlFor={code || value}
                    onClick={e => {
                      e.preventDefault()
                      onChange(value)
                    }}
                    style={{ letterSpacing: '1.1' }}
                  >
                    {name}
                  </label>
                </div>
              )
            })}
        </div>
        {!!errorMsg && <span className="form-error-msg">{errorMsg}</span>}
      </div>
    )
  }
  
  export { SwCheckboxSelect }  