import { useTranslation } from 'react-i18next'
import { Modal, SimpleImage } from '..'
import { getBrandRoute, getProductRoute, getAllAccountAddresses, getSavedCreditCardMethods } from '../../selectors'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, SwRadioSelect, OrderSummary, FulfillmentPicker, CartPromoBox } from '../'
import { useCheckoutUtilities } from '../../hooks'
import { PickupLocationDetails, ShippingAddressDetails, CheckPaymentDetails, BillingAddressDetails, CCDetails, CashPaymentDetails, PickupLocationPicker } from '../'
import { CHECK_PAYMENT_CODE } from '../../hooks/components/Checkout/useCheckoutUtilities'
import { useState, useEffect } from 'react'
import { addPaymentToOrder, addShippingMethod, addShippingAddressUsingAccountAddress, placeMyOrder, getPickupLocations, removeOrderPayment, removePromoCodeFromOrder, setPickupDateToOrderFulfillment, addPickupLocationToOrderFulfillment, getEligibleOrderFulfillmentMethods, changeFulfillmentOnOrder, applyPromoCodeToOrder } from '../../actions'
import { useDispatch } from 'react-redux'
import OrderConfirmation from '../../pages/OrderConfirmation/OrderConfirmation'
import { getErrorMessage, isAuthenticated } from '../../utils'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const OneClickCheckout = ({ sku, product, buyNowModal, setBuyNowModal, orderPreview, setOrderPreview, isOrderPlaced, setIsOrderPlaced }) => {
  const brand = useSelector(getBrandRoute)
  const producturl = useSelector(getProductRoute) || {}
  const { SHIPPING_CODE, PICKUP_CODE, CREDIT_CARD_CODE, CASH_PAYMENT_CODE, getPaymentMethodByIDFromList } = useCheckoutUtilities()
  const productLink = `/${producturl}/${product.urlTitle}` + (sku.skuID.length ? `?skuid=${sku.skuID}` : '')
  const { t } = useTranslation()
  const orderFulfillments = orderPreview?.orderFulfillments || []
  const accountAddresses = useSelector(getAllAccountAddresses)
  const payment = orderPreview.orderPayments?.at(0)
  const [showPaymentOptions, setShowPaymentOptions] = useState(false)
  const [showShippingOptions, setShowShippingOptions] = useState(false)
  const [showPickupLocationPicker, setShowPickupLocationPicker] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
  const [selectedShippingAddress, setSelectedShippingAddress] = useState('')
  const [selectedShippingMethod, setSelectedShippingMethod] = useState('')
  const [accountPaymentMethodID, setAccountPaymentMethodID] = useState('')
  const [pickupLocations, setPickupLocations] = useState([])
  const [eligibleFulfillmentMethods, setEligibleMethods] = useState({})
  const selectedFulfillmentMethod = orderFulfillments?.at(0)?.fulfillmentMethod || {}
  const paymentMethods = useSelector(getSavedCreditCardMethods)
  const orderID = orderPreview.orderID
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const eligiblePaymentMethodDetails = orderPreview?.eligiblePaymentMethodDetails
    ?.map(({ paymentMethod }) => {
      paymentMethod['value'] = paymentMethod.paymentMethodID
      paymentMethod['name'] = paymentMethod.paymentMethodName
      return paymentMethod
    })
    .filter(({ paymentMethodType }) => [CASH_PAYMENT_CODE, CREDIT_CARD_CODE].includes(paymentMethodType))

  const authAndDispatch = (functionName, params) => {
    if (!isAuthenticated()) navigate(`/my-account/login`)
    let finalResponse = dispatch(functionName(params)).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        if (response?.success()?.oneClickCheckoutOrder) setOrderPreview(response?.success()?.oneClickCheckoutOrder)
      }
      return response
    })
    return finalResponse
  }

  const onChangeDate = pickupDate => {
    authAndDispatch(setPickupDateToOrderFulfillment, { params: { orderID, pickupDate } })
  }

  const onChangeLocation = value => {
    authAndDispatch(addPickupLocationToOrderFulfillment, { params: { orderID, value } }).then(response => {
      if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length) setShowPickupLocationPicker(false)
    })
  }

  useEffect(() => {
    if (orderID) {
      if (!isAuthenticated()) navigate(`/my-account/login`)
      dispatch(
        getEligibleOrderFulfillmentMethods({
          params: {
            orderID,
          },
          isQuote: true,
        })
      ).then(response => {
        if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length) {
          setEligibleMethods(response?.success()?.eligibleFulfillmentMethods)
        }
      })
      dispatch(getPickupLocations()).then(response => {
        if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length) {
          setPickupLocations(response?.success()?.locations)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderID])

  return (
    <>
      <Modal show={buyNowModal} setShow={setBuyNowModal} title={`${t('frontend.product.buyNow')} : ${product.productName}`} size="xLarge">
        {isOrderPlaced ? (
          <OrderConfirmation />
        ) : (
          <>
            {buyNowModal && (
              <>
                <div className="container buynow-modal">
                  <div className="row col-12 py-2 my-2 border-bottom">
                    <div className="col-2">
                      <SimpleImage style={{ maxHeight: '100px' }} src={orderPreview?.orderItems?.at(0)?.sku?.imagePath || product.imagePath} />
                    </div>
                    <div className="col-8">
                      <Link to={`/${brand}/${product.brand_urlTitle}`} className="text-capitalize mb-3" style={{ fontSize: 12 }}>
                        {product.brand_brandName}
                      </Link>
                      <h2>
                        <Link to={productLink} className="product-name d-inline-block w-100">
                          {product.productName}
                        </Link>
                      </h2>
                      {!sku.skuCode && product.productCode && <div className="product-brand">{product.productCode}</div>}
                      {sku.skuCode && <div className="product-brand">{sku.skuCode}</div>}
                    </div>
                  </div>
                  <div className="row col-12 py-2 my-2 border-bottom">
                    <div className="col-2">
                      <h6>Ship To</h6>
                    </div>
                    <div className="col-8 row">
                      {orderFulfillments.length !== 0 &&
                        orderFulfillments.map(orderFulfillment => {
                          return (
                            <>
                              <div className="col-6" key={orderFulfillment.orderFulfillmentID}>
                                {orderFulfillment.fulfillmentMethod.fulfillmentMethodType === SHIPPING_CODE && (
                                  <>
                                    <ShippingAddressDetails orderFulfillment={orderFulfillment} displayOnly={true} />
                                    <button type="button" onClick={() => setShowShippingOptions(!showShippingOptions)} className="btn btn-outline-primary btn-sm">
                                      {t('frontend.core.edit')}
                                    </button>
                                  </>
                                )}
                                {orderFulfillment.fulfillmentMethod.fulfillmentMethodType === PICKUP_CODE && orderFulfillment?.pickupLocation && (
                                  <>
                                    <PickupLocationDetails pickupLocation={orderFulfillment?.pickupLocation} displayOnly={true} />
                                    <button
                                      className="btn btn-outline-primary btn-sm"
                                      onClick={() => {
                                        setShowPickupLocationPicker(!showPickupLocationPicker)
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </>
                                )}
                              </div>
                            </>
                          )
                        })}
                      {payment?.billingAddress?.addressID?.length > 0 && payment.paymentMethod.paymentMethodType !== CHECK_PAYMENT_CODE && (
                        <div className="col-6">
                          <BillingAddressDetails orderPayment={payment} />
                        </div>
                      )}
                      {(showShippingOptions || showPickupLocationPicker) && (
                        <div className="w-100 mt-2">
                          <hr />
                          <FulfillmentPicker
                            onSelect={() => {}}
                            orderItems={orderPreview?.orderItems}
                            eligibleFulfillmentMethods={eligibleFulfillmentMethods}
                            onChangeOrderFullfillment={(fulfillmentMethodID, orderItemIDList) => {
                              authAndDispatch(changeFulfillmentOnOrder, {
                                params: {
                                  orderID,
                                  fulfillmentMethodID,
                                  orderItemIDList,
                                },
                              }).then(response => {
                                if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length && !Object.keys(response.success()?.errors || {}).length) {
                                  let newAccountfulfillment = response?.success()?.oneClickCheckoutOrder?.orderFulfillments?.at(0)
                                  let fulfillmentMethod = response?.success()?.oneClickCheckoutOrder?.orderFulfillments?.at(0)?.fulfillmentMethod
                                  setSelectedShippingAddress(newAccountfulfillment?.accountAddress?.accountAddressID)
                                  setSelectedShippingMethod(newAccountfulfillment?.shippingMethod?.shippingMethodID)
                                  if (fulfillmentMethod?.fulfillmentMethodType === PICKUP_CODE) {
                                    setShowPickupLocationPicker(true)
                                    setShowShippingOptions(false)
                                  } else if (fulfillmentMethod?.fulfillmentMethodType === SHIPPING_CODE) {
                                    setShowPickupLocationPicker(false)
                                    setShowShippingOptions(true)
                                  }
                                }
                              })
                            }}
                            selectedFulfillmentMethod={selectedFulfillmentMethod}
                          />
                        </div>
                      )}
                      {showPickupLocationPicker && (
                        <div className="w-100 pickup-location-picker">
                          <PickupLocationPicker fulfillment={orderFulfillments.at(0)} onChangeDate={onChangeDate} onChangeLocation={onChangeLocation} pickupLocations={pickupLocations} />
                        </div>
                      )}
                      {orderFulfillments.length > 0 &&
                        orderFulfillments.map(orderFulfillment => {
                          return (
                            <div className="col-12" key={orderFulfillment.orderFulfillmentID}>
                              {showShippingOptions && (
                                <>
                                  <hr />
                                  <div className="row">
                                    <div className="col-6">
                                      <SwRadioSelect
                                        label={t('frontend.account.addresses')}
                                        options={accountAddresses.map(({ accountAddressName, accountAddressID, address }) => {
                                          const addressString = ` ${address.streetAddress}, ${address.city}, ${address.stateCode} ${address.postalCode}`
                                          const name = accountAddressName ? `${accountAddressName} - ${addressString} ` : addressString
                                          return { name, value: accountAddressID }
                                        })}
                                        onChange={value => {
                                          setSelectedShippingAddress(value)
                                          authAndDispatch(addShippingAddressUsingAccountAddress, {
                                            accountAddressID: value,
                                            fulfillmentID: orderFulfillment?.orderFulfillmentID,
                                            orderID,
                                          }).then(response => {
                                            let newAccountfulfillment = response?.success()?.oneClickCheckoutOrder?.orderFulfillments?.at(0)
                                            if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length && newAccountfulfillment?.accountAddress?.accountAddressID && newAccountfulfillment?.shippingMethod?.shippingMethodID && newAccountfulfillment?.orderFulfillmentID) setShowShippingOptions(false)
                                          })
                                        }}
                                        customLabel={t('frontend.checkout.receive_option')}
                                        selectedValue={selectedShippingAddress?.length > 0 ? selectedShippingAddress : orderFulfillment?.accountAddress?.accountAddressID}
                                        displayNew={true}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <SwRadioSelect
                                        label={t('frontend.checkout.delivery_option')}
                                        options={orderFulfillment.shippingMethodOptions}
                                        onChange={value => {
                                          authAndDispatch(addShippingMethod, {
                                            shippingMethodID: value,
                                            fulfillmentID: orderFulfillment.orderFulfillmentID,
                                            orderID,
                                          }).then(response => {
                                            let newAccountfulfillment = response?.success()?.oneClickCheckoutOrder?.orderFulfillments?.at(0)
                                            if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length && newAccountfulfillment?.accountAddress?.accountAddressID && newAccountfulfillment?.shippingMethod?.shippingMethodID && newAccountfulfillment?.orderFulfillmentID) setShowShippingOptions(false)
                                          })
                                          setSelectedShippingMethod(value)
                                        }}
                                        selectedValue={selectedShippingMethod?.length > 0 ? selectedShippingMethod : orderFulfillment?.shippingMethod?.shippingMethodID}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                  <div className="row col-12 py-2 my-2 border-bottom">
                    <div className="col-2">
                      <h6>Payment with</h6>
                    </div>
                    <div className="col-8 row">
                      <div className="col-6">
                        {payment?.paymentMethod.paymentMethodType === CREDIT_CARD_CODE && <CCDetails creditCardPayment={payment} />}
                        {payment?.paymentMethod.paymentMethodType === CASH_PAYMENT_CODE && <CashPaymentDetails cashPayment={payment} />}
                        {payment?.paymentMethod.paymentMethodType === CHECK_PAYMENT_CODE && <CheckPaymentDetails payment={payment} />}

                        <button
                          type="button"
                          onClick={() => {
                            authAndDispatch(removeOrderPayment, { params: { orderPaymentID: payment?.orderPaymentID, orderID } })
                            setShowPaymentOptions(true)
                          }}
                          className="btn btn-outline-primary btn-sm"
                        >
                          {t('frontend.core.edit')}
                        </button>
                      </div>
                      <div className="col-6">
                        {showPaymentOptions && eligiblePaymentMethodDetails?.length > 0 && (
                          <SwRadioSelect
                            label={t('frontend.checkout.payment.select')}
                            options={eligiblePaymentMethodDetails}
                            onChange={paymentMethodID => {
                              const foundPaymentMethod = getPaymentMethodByIDFromList(eligiblePaymentMethodDetails, paymentMethodID)
                              setSelectedPaymentMethod(foundPaymentMethod)
                              if (foundPaymentMethod.paymentMethodType === CASH_PAYMENT_CODE) {
                                authAndDispatch(addPaymentToOrder, {
                                  params: {
                                    newOrderPayment: {
                                      paymentMethod: {
                                        paymentMethodID,
                                      },
                                    },
                                    orderID,
                                  },
                                }).then(response => {
                                  if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length) setShowPaymentOptions(false)
                                })
                              } else {
                                authAndDispatch(removeOrderPayment, { params: { orderPaymentID: payment?.orderPaymentID, orderID } })
                              }
                            }}
                            selectedValue={selectedPaymentMethod?.paymentMethodID?.length ? selectedPaymentMethod.paymentMethodID : payment?.paymentMethod?.paymentMethodID}
                          />
                        )}
                      </div>
                      <div className="row col-12">
                        {showPaymentOptions && selectedPaymentMethod.paymentMethodType === CREDIT_CARD_CODE && (
                          <SwRadioSelect
                            label={t('frontend.checkout.cardInfo')}
                            options={paymentMethods}
                            onChange={value => {
                              authAndDispatch(addPaymentToOrder, {
                                params: {
                                  accountPaymentMethodID: value,
                                  orderID,
                                },
                              }).then(response => {
                                if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length) setShowPaymentOptions(false)
                              })
                              setAccountPaymentMethodID(value)
                            }}
                            selectedValue={accountPaymentMethodID ? accountPaymentMethodID : payment?.accountPaymentMethod?.accountPaymentMethodID}
                            displayNew={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row col-12 py-2 my-2">
                    <div className="col-2">
                      <h6>Total</h6>
                    </div>
                    <div className="col-5">
                      <OrderSummary
                        cart={orderPreview}
                        onRemovePromoCode={(event, promoCode) => {
                          authAndDispatch(removePromoCodeFromOrder, { params: { orderID, promotionCode: promoCode }, isQuote: true, returnQuote: false })
                        }}
                      />
                    </div>
                    <div className="col-5">
                      <CartPromoBox
                        onApplyCode={(promoCode, setPromoCode) => {
                          if (!promoCode) return null
                          authAndDispatch(applyPromoCodeToOrder, { params: { orderID, promotionCode: promoCode } }).then(response => {
                            if (response.isSuccess()) {
                              setPromoCode('')
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex col-12 mt-4 justify-content-end">
                  <Button
                    classList="btn btn-default btn-warning mx-1"
                    label="Place Your Order"
                    onClick={e => {
                      e.preventDefault()
                      authAndDispatch(placeMyOrder, { params: { orderID } }).then(response => {
                        if (response.isSuccess() && !Object.keys(response.success()?.errors || {}).length) setIsOrderPlaced(true)
                      })
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export { OneClickCheckout }
