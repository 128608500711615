import { useTranslation } from 'react-i18next'
import { useFormatCurrency } from '../../../hooks'

const GiftCardDetails = ({ hideHeading, payment }) => {
  const { t } = useTranslation()
  const [formatCurrency] = useFormatCurrency({})
  return (
    <>
      <p>{t('frontend.checkout.payment.giftCard.heading')}</p>
      <p>{t('frontend.checkout.amount_deducted')}  {formatCurrency(payment?.amount)}</p>
    </>
  )
}

export { GiftCardDetails }
