import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../actions'
import { useProductPrice } from '../../hooks'
import { Button, ProductQuantityInput, OneClickCheckout } from '../'
import { isAuthenticated, toBoolean, getErrorMessage } from '../../utils'
import { Link } from 'react-router-dom'
import { axios, sdkURL } from '../../services'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const ProductForm = ({ sku, isDisabled, isLoading, quantity, setQuantity, skuAddonsIDList, setTotalAddons, product }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { showAddToCart } = useProductPrice({ salePrice: sku?.salePrice, listPrice: sku?.listPrice })
  const [buyNowModal, setBuyNowModal] = useState(false)
  const [isDisabledBuyNowButton, setIsDisabledBuyNowButton] = useState(false)
  const [orderPreview, setOrderPreview] = useState({})
  const user = useSelector(state => state.userReducer)
  const authenticationRequiredForAddToCart = !!sku?.settings?.skuRequireLoginToAddToCart ? !isAuthenticated() : false
  const allowBuyNow = sku?.settings?.skuAllowBuyNowFlag && isAuthenticated() && user?.primaryPaymentMethod?.accountPaymentMethodID && user?.primaryAddress?.accountAddressID ? true : false
  const [isOrderPlaced, setIsOrderPlaced] = useState(false)

  const initiateOneClickCheckout = () => {
    setIsDisabledBuyNowButton(true)
    setIsOrderPlaced(false)
    const primaryPaymentMethod = user.primaryPaymentMethod
    const primaryAddress = user.primaryAddress

    axios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/initiateOneClickCheckoutOrder`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        skuID: sku.skuID,
        quantity: quantity,
        accountAddressID: primaryAddress.accountAddressID,
        accountPaymentMethodID: primaryPaymentMethod.accountPaymentMethodID,
      },
    }).then(response => {
      setIsDisabledBuyNowButton(false)
      if (Object.keys(response.data?.error || {}).length) toast.error(getErrorMessage(response?.data?.error))
      if (Object.keys(response.data?.errors || {}).length) toast.error(getErrorMessage(response?.data?.errors))
      if (response?.status === 200 && response?.data?.failureActions?.length === 0) {
        if (response.data?.error && response.data?.error !== '') {
          toast.error(response.data.error)
        } else {
          setOrderPreview(response?.data?.oneClickCheckoutOrder)
          setBuyNowModal(true)
        }
      } else {
      }
    })
  }

  if (toBoolean(sku?.settings?.skuAllowOrderTemplateFlag)) return null
  return (
    <>
      {showAddToCart && !authenticationRequiredForAddToCart && (
        <>
          <div className="form-group me-2 mb-4">
            <ProductQuantityInput setQuantity={setQuantity} quantity={quantity} sku={sku} stock={sku.calculatedQATS || sku.stocks_calculatedQATS} />
          </div>
          <div className="form-group my-auto d-flex">
            <Button
              disabled={isDisabled || quantity < 1}
              isLoading={isLoading}
              classList="btn btn-primary btn-block mb-3"
              label={t('frontend.product.add_to_cart')}
              onClick={event => {
                event.preventDefault()
                dispatch(addToCart(sku.skuID, quantity, skuAddonsIDList)).then(() => {
                  setTotalAddons({})
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                })
              }}
            />
            {allowBuyNow && (
              <Button
                classList="btn btn-default text-primary border-primary mx-1 mb-3"
                label={t('frontend.product.buyNow')}
                disabled={isDisabledBuyNowButton}
                onClick={e => {
                  e.preventDefault()
                  initiateOneClickCheckout()
                }}
              />
            )}
          </div>
        </>
      )}

      {showAddToCart && authenticationRequiredForAddToCart && (
        <div className="alert alert-warning fs-6" role="alert">
          {t('frontend.product.loginToAdd')}
          <br />
          <Link to="/my-account/login"> {t('frontend.account.login')} </Link>
        </div>
      )}
      <OneClickCheckout sku={sku} product={product} buyNowModal={buyNowModal} setBuyNowModal={setBuyNowModal} orderPreview={orderPreview} setOrderPreview={setOrderPreview} isOrderPlaced={isOrderPlaced} setIsOrderPlaced={setIsOrderPlaced} />
    </>
  )
}
export { ProductForm }
