import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFormatCurrency } from '../../../hooks'
import { getAllAccountPaymentMethods } from '../../../selectors/orderSelectors'

const CCDetails = ({ creditCardPayment, hideHeading = false }) => {
  const { paymentMethod, creditCardType, nameOnCreditCard, creditCardLastFour, accountPaymentMethod, amount } = creditCardPayment
  const paymentMethods = useSelector(getAllAccountPaymentMethods)
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  return (
    <>
      <p className="mb-1">
        {accountPaymentMethod?.accountPaymentMethodID?.length > 0 &&
          paymentMethods.map(method => {
            return method.accountPaymentMethodID === accountPaymentMethod?.accountPaymentMethodID ? <em key={accountPaymentMethod?.accountPaymentMethodID}>{method?.accountPaymentMethodName}</em> : null
          })}
        <br />
        <em>{paymentMethod?.paymentMethodName}</em>
        <br />
        {nameOnCreditCard} <br />
        {`${creditCardType} ending in ${creditCardLastFour}`}<br />
        {t('frontend.checkout.amount_deducted') + ' ' +formatCurrency(amount)} 
      </p>
    </>
  )
}

export { CCDetails }
