import { DynamicForm } from './DynamicForm'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAccountProfile, useGetEntityByID } from '../../hooks'
import { t } from 'i18next'
import { SlatwalApiService, axios } from '../../services'
import { getErrorMessage } from '../../utils'
import { Modal } from '../Modal/Modal'

/*
question: {
    attributeName: string,
    attributeCode: string,
    inputType: string,
    options: {
        value, name
    },
    validationMessage: string,
    validationRegex:string,
    requiredFlag:boolean,
}
*/

const customDynamicFormProps = {
  onFormSubmit: payload => console.log(payload),
  submitButtonText: 'frontend.bulkorder.add_to_list',
  submitButtonIcon: 'bi-plus-circle',
}
const EventAttendeeForm = props => {
  const { orderID, orderItem } = props
  const { orderItemID, quantity } = orderItem
  const [registrations, setRegistrations] = useState(orderItem.eventRegistrations ?? [])
  const [formData, setFormData] = useState({})
  const { formik } = useAccountProfile()
  const [productDetail, getProductDetail] = useGetEntityByID()
  const [eventDisplayConfig, setEventDisplayConfig] = useState()
  const [confirmModal, setConfirmModal] = useState(false)

  // use this to get the product details, including eventDisplayConfig for passing to the Dynamic Form
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getProductDetail({ ...productDetail, isFetching: false, isLoaded: false, entity: 'product', includeSettings: true, includeAttributesMetadata: true, params: { entityID: orderItem.sku.product.productID }, makeRequest: true }), [])
  useEffect(() => {
    if (productDetail?.data?.product?.eventDisplayConfig?.length > 1) {
      let eventDisplayConfigString = productDetail.data.product.eventDisplayConfig
      setEventDisplayConfig(JSON.parse(eventDisplayConfigString))
    }
  }, [productDetail])

  const addEventRegistrationAttendee = attendee => {
    return SlatwalApiService.sdkScope.httpService.slatwallResponse('post', 'api/scope/addEventRegistrationAttende', { ...attendee, returnJSONObjects: 'order' }, {}, axios.CancelToken.source()).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        const currentEventRegistrations = response
          .success()
          // ? Is there a way to return only the new eventRegistration ?
          .order.orderItems.find(oi => oi.orderItemID === orderItemID).eventRegistrations
        return currentEventRegistrations
      }
      throw new Error(response.fail())
    })
  }
  const removeEventRegistrationAttendee = ({ eventRegistrationID, orderID }) => {
    console.table({ eventRegistrationID, orderID })
    return SlatwalApiService.sdkScope.httpService.slatwallResponse('post', 'api/scope/removeEventRegistrationAttende', { eventRegistrationID, orderID, returnJSONObjects: 'order' }, {}, axios.CancelToken.source()).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        console.log(response.success())
        const currentEventRegistrations = response
          .success()
          // ? Is there a way to return only the removed eventRegistration ?
          .order.orderItems.find(oi => oi.orderItemID === orderItemID).eventRegistrations
        return currentEventRegistrations
      }
      if (response.isFail()) {
        console.error(response.fail())
      }
      throw new Error(response.fail())
    })
  }

  const removeAttendee = e => {
    e.preventDefault()
    removeEventRegistrationAttendee({ orderID, eventRegistrationID: confirmModal })
      .then(setRegistrations)
      .catch(err => console.error(err))
    setConfirmModal(false)
  }

  const removeAttendeeModal = eventRegistrationID => e => {
    e.preventDefault()
    setConfirmModal(eventRegistrationID)
  }

  const formAction = payload => {
    if (registrations.length < quantity)
      addEventRegistrationAttendee({
        orderID,
        orderItemID,
        ...payload,
      })
        .then(setRegistrations)
        .catch(err => console.error(err))
    else toast.error(t('frontend.eventattendee.maxAttendeeErrorMessage'))
  }

  const populateWithCurrentUserAction = e => {
    e.preventDefault()
    setFormData({
      firstName: formik.values.accountFirstName,
      lastName: formik.values.accountLastName,
      email: formik.values.accountEmailAddress,
    })
  }

  const populateWithCurrentUserButton = (
    <button className="btn btn-primary btn-block mt-2" onClick={populateWithCurrentUserAction}>
      <span className="d-sm-inline">{t('frontend.eventattendee.populateWithCurrentUser')}</span>
      <i className="bi bi-arrow-up-circle float-end mt-1 ms-2"></i>
    </button>
  )

  return (
    !!eventDisplayConfig?.formQuestions && (
      <div>
        <h2>{t('frontend.eventattendee.addAttendeeHeading')}</h2>
        <DynamicForm {...props} {...customDynamicFormProps} {...eventDisplayConfig} onFormSubmit={formAction} additionalControls={[populateWithCurrentUserButton]} payloadStateHook={[formData, setFormData]} submitDisabled={quantity - registrations.length === 0} />
        <div>
          <span className="text-muted">
            {quantity - registrations.length} {t('frontend.eventattendee.of')} {quantity} {t('frontend.eventattendee.availableSeats')}
          </span>
        </div>
        <div className="table-responsive font-size-md">
          <table className="table table-striped table-bordered  table-hover mt-3">
            <thead className="thead-light">
              <tr>
                {eventDisplayConfig?.formQuestions?.map(question => (
                  <th className="dark-grey-text h6" key={question.attributeCode}>
                    {question.attributeName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[...registrations].reverse().map((registration, index) => (
                <tr key={index}>
                  {eventDisplayConfig?.formQuestions?.map(question => (
                    <td key={`${question.attributeCode}-${index}`} className="align-middle">
                      {registration[question.attributeCode]}
                    </td>
                  ))}
                  <td className="text-center">
                    <i className="bi bi-trash3 float-end mt-1 ms-2" onClick={removeAttendeeModal(registration.eventRegistrationID)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal show={confirmModal} setShow={setConfirmModal} title={t('frontend.eventattendee.removeFromListConfirmation.title')}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '1em', alignContent: 'center' }}>
            <div style={{ textAlign: 'center' }}>{t('frontend.eventattendee.removeFromListConfirmation.body')}</div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <button type="submit" className="btn btn-primary btn-lg" onClick={removeAttendee}>
                {t('frontend.eventattendee.removeFromListConfirmation.confirmButtonText')}
              </button>
              <button type="submit" className="btn btn-primary btn-lg" onClick={() => setConfirmModal(false)}>
                {t('frontend.eventattendee.removeFromListConfirmation.cancelButtonText')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  )
}

export { EventAttendeeForm }
